export const EN = {
  language: 'en',
  login: 'Log in',
  loginGreeting: 'Nice to see you!',
  logging: 'Logging in...',
  logout: 'Log out',
  register: 'Register',
  registerGreeting: 'You are welcome!',
  registration: 'Registration...',
  notRegistered: 'Not registered?',
  registered: 'Already registered?',
  placeholderName: 'Enter your name',
  placeholderEmail: 'Enter your email',
  password: 'Password',
  placeholderPassword: 'Enter your password',

  profile: 'Profile',
  profileGreeting: 'Hello',
  profileName: 'Name',
  profileNewName: 'New name',
  profileNewEmail: 'New email',
  saveBtn: 'Save',
  saving: 'Saving...',
  editBtn: 'Edit profile',
  canselBtn: 'Cancel',

  homePage: 'Homepage',
  movies: 'Movies',
  savedMovies: 'Saved movies',
  placeholderMovie: 'Movie',
  shortMovies: 'Short movies',
  moreBtn: 'More',
  noResults: 'No results.',

  logo: 'Logo',
  heading: 'Web development student project.',
  knowMore: 'Scroll below to learn more about this project and its creator.',
  viewProject: 'View the Project',

  aboutProject: 'About Project',
  stepsHeading: 'The diploma project included 5 stages',
  stepsText: 'Making a plan, working on the backend, layout, adding functionality and final refinements.',
  timeHeading: 'The diploma took 5 weeks to complete',
  timeText: 'Each stage had soft and hard deadlines that had to be met in order to successfully defend themselves.',
  backendTime: '1 week',
  frontendTime: '4 weeks',

  technologies: 'Technologies',
  techsHeading: '7 technologies',
  techsText: 'On the course of web development, we mastered the technologies that we used in the thesis project.',

  student: 'Student',
  studentName: 'Olga',
  studentAbout: 'Web developer, 38',
  studentText: 'Before I decided to change careers, I worked with clients for many years and also managed more than 40 events for up to 100 participants. I am interested in photography and love to travel.',
  studentFoto: "Student's foto",

  portfolio: 'Portfolio',
  staticWebsite: 'Static website',
  responsiveWebsite: 'Responsive website',
  singlePageApp: 'Single-page application',
  arrow: 'Arrow',

  credentials: 'Y.Praktikum x BeatFilm training project.',

  noMatch: 'Page is not found',
  goBack: 'Back',
  isDataChanged: 'Your data has been successfully changed.',
  isSignup: 'Registration is successfully.',
  notFoundErr: 'Data not found.',
  badRequestErr: 'Invalid data.',
  conflictErr: 'This email already exists.',
  forbiddenErr: 'No rights for this action.',
  authErr: 'Invalid email or password.',
  serverErr: 'Server error.',
  pathNotFound: 'Path not found.',
  unauthorized: 'Authorisation is required.'
}

export const RU = {
  language: 'ru',
  login: 'Вход',
  loginGreeting: 'Рады видеть!',
  logging: 'Вход...',
  logout: 'Выйти из аккаунта',
  register: 'Зарегистрироваться',
  registerGreeting: 'Добро пожаловать!',
  registration: 'Регистрация...',
  notRegistered: 'Еще на зарегистрированы?',
  registered: 'Уже зарегистрированы?',
  placeholderName: 'Как вас называть?',
  placeholderEmail: 'Введите ваш email',
  password: 'Пароль',
  placeholderPassword: 'Введите ваш пароль',

  profile: 'Аккаунт',
  profileGreeting: 'Привет,',
  profileName: 'Имя',
  profileNewName: 'Новое имя',
  profileNewEmail: 'Новый email',
  saveBtn: 'Сохранить',
  saving: 'Сохранение...',
  editBtn: 'Редактировать',
  canselBtn: 'Отмена',

  homePage: 'Главная',
  movies: 'Фильмы',
  savedMovies: 'Сохраненные фильмы',
  placeholderMovie: 'Фильм',
  shortMovies: 'Короткометражки',
  moreBtn: 'Еще',
  noResults: 'Поиск не дал результатов.',

  logo: 'Логотип',
  heading: 'Учебный проект студента факультета Веб-разработки.',
  knowMore: 'Листайте ниже, чтобы узнать больше про этот проект и его создателя.',
  viewProject: 'Посмотреть проект',

  aboutProject: 'О проекте',
  stepsHeading: 'Дипломный проект включал 5 этапов',
  stepsText: 'Составление плана, работу над бэкендом, вёрстку, добавление функциональности и финальные доработки.',
  timeHeading: 'На выполнение диплома ушло 5 недель',
  timeText: 'У каждого этапа был мягкий и жёсткий дедлайн, которые нужно было соблюдать, чтобы успешно защититься.',
  backendTime: '1 неделя',
  frontendTime: '4 недели',

  technologies: 'Технологии',
  techsHeading: '7 технологий',
  techsText: 'На курсе веб-разработки мы освоили технологии, которые применили в дипломном проекте.',

  student: 'Студентка',
  studentName: 'Ольга',
  studentAbout: 'Веб-разработчик, 38 лет',
  studentText: 'До того, как я решила сменить профессию, я много лет работала с клиентами, а также организовала более 40 мероприятий до 100 участников. Увлекаюсь фотографией и люблю путешествовать.',
  studentFoto: 'Фотография студента',

  portfolio: 'Портфолио',
  staticWebsite: 'Статичный сайт',
  responsiveWebsite: 'Адаптивный сайт',
  singlePageApp: 'Одностраничное приложение',
  arrow: 'Arrow',

  credentials: 'Учебный проект Y.Praktikum х BeatFilm.',

  noMatch: 'Страница не найдена',
  goBack: 'Назад',
  isDataChanged: 'Ваши данные успешно изменены.',
  isSignup: 'Вы успешно зарегистрировались.',
  notFoundErr: 'Переданные данные не найдены.',
  badRequestErr: 'Переданы некорректные данные.',
  conflictErr: 'Такой email уже существует',
  forbiddenErr: 'Нет прав для данного действия.',
  authErr: 'Некорректный email или пароль.',
  serverErr: 'На сервере произошла ошибка.',
  pathNotFound: 'Путь не найден.',
  unauthorized: 'Необходима авторизация.'
}

export const DE = {
  language: 'de',
  login: 'Anmelden',
  loginGreeting: 'Schön, Sie zu sehen!',
  logging: 'Anmeldung...',
  logout: 'Abmelden',
  register: 'Registrieren',
  registerGreeting: 'Herzlich willkommen!',
  registration: 'Registrierung...',
  notRegistered: 'Noch nicht registriert?',
  registered: 'Schon registriert?',
  placeholderName: 'Geben Sie Ihren Namen ein',
  placeholderEmail: 'Geben Sie Ihre Email ein',
  password: 'Passwort',
  placeholderPassword: 'Geben Sie Ihre Email ein',

  profile: 'Profil',
  profileGreeting: 'Hallo',
  profileName: 'Name',
  profileNewName: 'Neuer Name',
  profileNewEmail: 'Neue Email',
  saveBtn: 'Speichern',
  saving: 'Wird gespeichert...',
  editBtn: 'Profil bearbeiten',
  canselBtn: 'Absagen',

  homePage: 'Startseite',
  movies: 'Filme',
  savedMovies: 'Gespeicherte Filme',
  placeholderMovie: 'Film',
  shortMovies: 'Kurzfilme',
  moreBtn: 'Mehr',
  noResults: 'Keine Ergebnisse.',

  logo: 'Das Logo',
  heading: 'Studentenprojekt zur Web-Entwicklung.',
  knowMore: 'Scrollen Sie nach unten, um mehr über dieses Projekt und seinen Schöpfer zu erfahren.',
  viewProject: 'Das Projekt ansehen',

  aboutProject: 'Über das Projekt',
  stepsHeading: 'Das Diplomprojekt umfasste 5 Etappen',
  stepsText: 'Erstellung eines Plans, Arbeit am Backend, Layout, Hinzufügen von Funktionen und letzte Verfeinerungen.',
  timeHeading: 'Die Fertigstellung des Diploms dauerte 5 Wochen',
  timeText: 'Für jede Etappe gab es weiche und harte Fristen, die eingehalten werden mussten, um sich erfolgreich verteidigen zu können.',
  backendTime: '1 Woche',
  frontendTime: '4 Wochen',

  technologies: 'Technologien',
  techsHeading: '7 Technologien',
  techsText: 'Im Rahmen des Kurses haben wir uns die Technologien angeeignet, die wir im Abschlussprojekt verwendet haben.',

  student: 'Studentin',
  studentName: 'Olga',
  studentAbout: 'Web-Entwicklerin, 38 Jahre',
  studentText: 'Bevor ich mich für einen Quereinstieg entschied, hatte ich viele Jahre lang in der Verakuf und Kundenbetreuung gearbeitet. Als Ehrenamtliche Clubleiterin habe mehr als 40 Veranstaltungen mit bis zu 100 Teilnehmern organisiert. Ich habe eine Leidenschaft für die Fotografie und mag es zu verreisen.',
  studentFoto: "Das Foto von Studentin",

  portfolio: 'Portfolio',
  staticWebsite: 'Statische Website',
  responsiveWebsite: 'Responsive Website',
  singlePageApp: 'Single-Page Application',
  arrow: 'Der Pfeil',

  credentials: 'Y.Praktikum x BeatFilm Ausbildungsprojekt.',

  noMatch: 'Die Seite wurde nicht gefunden',
  goBack: 'Zurück',
  isDataChanged: 'Ihre Daten wurden erfolgreich geändert.',
  isSignup: 'Die Anmeldung ist erfolgreich.',
  notFoundErr: 'Daten wurden nicht gefunden.',
  badRequestErr: 'Ungültige Daten.',
  conflictErr: 'Diese Email ist bereits vorhanden.',
  forbiddenErr: 'Keine Rechte für diese Aktion.',
  authErr: 'Ungültige Email oder Passwort.',
  serverErr: 'Serverfehler',
  pathNotFound: 'Pfad wurde nicht gefunden.',
  unauthorized: 'Anmeldung ist erforderlich.'
}


